<script setup lang="ts">
import Carousel, { CarouselImage } from './components/Carousel.vue';
import CloseButton from './components/CloseButton.vue';
import { ref, watch } from 'vue';

const props = defineProps<{
  images: CarouselImage[];
}>();
const handleArrowKeysClick = (e: any) => {
  switch (e.key) {
    case 'ArrowLeft':
      currentIndex.value = getNextIndex(-1);
      break;
    case 'ArrowRight':
      currentIndex.value = getNextIndex(1);
      break;
  }
};
const handleImageClick = () => (fullScreen.value = true);
const fullScreen = ref(false);
const currentIndex = ref(0);
const getNextIndex = (offset: 1 | -1) => {
  return offset + currentIndex.value < 0
    ? props.images.length - 1
    : offset + currentIndex.value == props.images.length
    ? 0
    : offset + currentIndex.value;
};

watch(fullScreen, () => {
  if (fullScreen.value) {
    window.addEventListener('keydown', handleArrowKeysClick, false);
  } else {
    window.removeEventListener('keydown', handleArrowKeysClick, false);
  }
});
</script>

<template>
  <Carousel
    :images="images"
    v-model:currentIndex="currentIndex"
    @imageClicked="handleImageClick"
    imageClickable
  />
  <div v-if="fullScreen" class="fullscreen">
    <Carousel
      :images="images"
      v-model:currentIndex="currentIndex"
      :fillScreen="true"
    />
  </div>
  <div v-if="fullScreen" class="close-btn" @click="fullScreen = false">
    <CloseButton />
  </div>
</template>

<style scoped>
h2 {
  position: absolute;
  right: 0;
}

.fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100000;
}

.close-btn {
  position: fixed;
  right: 0;
  top: 0;
  margin: 20px;
  z-index: 100001;
}
</style>